<template>
  <div class="fluid" id="skills">
    <b-card style="width: 80%; margin: 0 auto; margin-bottom: 20px">
      <div class="row d-flex align-items-center">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <label for="" class="mx-1 label-font">Search:</label>
              <b-form-group>
                <b-form-input v-debounce:300ms="getSkills" v-model="query.keyword" type="text" placeholder="Search"
                  class="search-input bg-light" />
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="col-md-3 p-0">
          <b-button v-if="$can('create', 'Skill')" v-b-modal.modal-prevent-closing
            class="btn mt-1 mt-lg-0 add-btn d-block ml-auto">
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add New Skill </span>
              <span>
                <img src="@/assets/images/icons/add-icon.png" alt="add icon" height="20" width="20" />
              </span>
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
    <div class="table-responsive">
      <b-table :items="skills" :fields="parentFields" show-empty responsive striped hover
        class="bg-white cursor-icon short-table" style="width: 80%; margin: 0 auto" :per-page="query.per_page">
        <template #cell(id)="row">
          <div :class="`p-1 ${row.item.status === 1 ? 'active-success' : 'active-danger'
            }`">
            <p>
              {{ row.index + 1 }}
            </p>
          </div>
        </template>
        <template #cell(name)="row">
          <p>
            {{ row.item.skill }}
          </p>
        </template>
        <template #cell(action)="row">
          <div>
            <button v-if="$can('update', 'Skill')" v-b-modal.modal-prevent-closing @click.prevent="getSkill(row.item.id)"
              class="btn btn-info d-block ml-auto">
              Edit
            </button>
          </div>
        </template>
      </b-table>
    </div>

    <b-modal id="modal-prevent-closing" ref="modal" :title="skill.id ? 'Update Skill' : 'Create New Skill'" @show="resetModal"
      @hidden="resetModal" @ok="handleOk" :ok-title="skill.id ? 'Update' : 'Save'">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label="Name" label-for="name-input" invalid-feedback="Name is required" :state="nameState">
          <b-form-input id="name-input" v-model="skill.skill" :state="nameState" required class="mb-2 bg-light"
            placeholder="Enter Skill name"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { toast } from "@/utils/toast";

export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
  },

  data() {
    return {
      homeLink: false,
      skills: [],
      nameState: null,
      skill: {
        name: "",
      },
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getSkills();
  },
  methods: {
    getSkills() {
      this.$store
        .dispatch("skill/getSkills", this.query)
        .then((response) => {
          this.skills = response.data;
        });
    },
    async getSkill(id) {
      this.query.id = id;
      // this.query.skill_id = id;
      this.$store
        .dispatch("skill/getSkills", this.query)
        .then((response) => {
          this.skill = response.data[0];
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.nameState = null;
      this.skill = {
        name: "",
      };
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.skill.id) {
        this.updateSkill();
      } else {
        this.createSkill();
      }
    },
    createSkill() {
      this.$store
        .dispatch("skill/createSkill", this.skill)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");

          this.getSkills();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Skill Create Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
    updateSkill() {
      this.skill.skill_id = this.skill.id;
      this.skill.id = this.skill.user_id;
      this.$store
        .dispatch("skill/updateSkill", this.skill)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.query.id = null;
          this.getSkills();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "Skill Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    }
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
